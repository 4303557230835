import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextMediaSplit = makeShortcode("TextMediaSplit");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextMediaSplit imagePath="contact/about-me.png" imageAlt="My hobbies" mdxType="TextMediaSplit">   
      <h2>{`Thank You`}</h2>
      <p>{`Thank you for your message!  `}</p>
      <p>{`Emily will endeavour to respond as soon as she can.`}</p>
      <p>{`In the meantime, `}<a parentName="p" {...{
          "href": "/"
        }}>{`click here to return home`}</a>{`.`}</p>
    </TextMediaSplit>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      